<template>
  <b-form :class="$style.wrapper" @submit.stop.prevent="showTable">
    <b-row>
      <b-col sm="8" md="6" lg="8" xl="6">
        <b-form-group label="Отчет:">
          <base-select v-model="selected" :options="options"></base-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="date in dates" :key="date.label" sm="4" md="3" lg="4" xl="3">
        <b-form-group :label="date.label">
          <base-input-date v-model="date.value" @input="date.action()"></base-input-date>
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <BaseButton>
      <template #text>
        Показать
      </template>
    </BaseButton>
  </b-form>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import {
  ShowCurrentDate,
  SetDateIntervalEnd,
  SetDateIntervalStart
} from '@/additional_helpers/dates';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';

import URL from '@/basUrl';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      selected: 'Входящие звонки',
      options: [
        { text: 'Входящие звонки', value: 'Входящие звонки' },
        { text: 'Исходящие звонки', value: 'Исходящие звонки' },
        { text: 'Пропущенные звонки', value: 'Пропущенные звонки' }
      ],
      dates: [
        { label: 'Начало интервала', value: ShowCurrentDate(), action: this.changeDateIntervalEnd },
        { label: 'Конец интервала', value: ShowCurrentDate(), action: this.changeDateIntervalStart }
      ]
    };
  },

  components: {
    BaseButton,
    BaseSelect,
    BaseInputDate
  },

  methods: {
    ...mapActions({ getCalls: 'callsAnalysis/getCalls' }),

    changeDateIntervalEnd() {
      this.dates[1].value = SetDateIntervalEnd(this.dates[0].value, this.dates[1].value);
    },

    changeDateIntervalStart() {
      this.dates[0].value = SetDateIntervalStart(this.dates[0].value, this.dates[1].value);
    },

    showTable() {
      const [intervalStart, intervalEnd] = this.dates;
      const url = this.setUrl();
      const query = `start=${intervalStart.value}&end=${intervalEnd.value}`;

      this.getCalls({ query, url, vm: this });
    },

    setUrl() {
      switch (this.selected) {
        case 'Исходящие звонки':
          return URL.SERVICE_OUTGOING;
        case 'Пропущенные звонки':
          return URL.SERVICE_MISSED;
        default:
          return URL.SERVICE_INCOMING;
      }
    }
  }
};
</script>

<style module>
.wrapper {
  padding-top: 1rem;
}
</style>
